import {IEntityAction, ActionMeta} from 'app/blocks/store/actions/entity-actions';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {IInvoice} from 'app/blocks/model/invoice.model';

export class MarkInvoiceAsReady implements IEntityAction {
    static readonly type = '[Invoice] Mark As Ready';
    constructor(
        public resource: AbstractEntityResource<any>,
        public id: number,
        public meta?: ActionMeta
    ) {}
}

export class InvoiceCustomer implements IEntityAction {
    static readonly type = '[Invoice] Invoice Customer';
    constructor(
        public resource: AbstractEntityResource<any>,
        public id: number,
        public meta?: ActionMeta
    ) {}
}

export class CancelInvoice implements IEntityAction {
    static readonly type = '[Invoice] Cancel';
    constructor(
        public resource: AbstractEntityResource<any>,
        public id: number,
        public meta?: ActionMeta
    ) {}
}

export class UnCancelInvoice implements IEntityAction {
    static readonly type = '[Invoice] UnCancel';
    constructor(
        public resource: AbstractEntityResource<any>,
        public id: number,
        public meta?: ActionMeta
    ) {}
}

export interface IUpdateInvoiceAllocated extends IEntityAction {
    entity?: IInvoice;
}

export class UpdateInvoiceAllocated implements IUpdateInvoiceAllocated {
    static readonly type = '[Invoice] Update Invoice';
    constructor(
        public resource: AbstractEntityResource<any>,
        public entity: IInvoice,
        meta?: ActionMeta
    ) {}
}
